import { IconCaretDownMd } from '@troon/icons';
import { twMerge } from '@troon/tailwind-preset/merge';
import type { ParentProps } from 'solid-js';

export function Details(props: ParentProps<{ class?: string }>) {
	return <details class={twMerge('group text-sm', props.class)}>{props.children}</details>;
}

export function Summary(props: ParentProps<{ class?: string }>) {
	return (
		<summary
			class={twMerge(
				'flex cursor-pointer list-none items-center justify-between text-base group-open:mb-4 details-marker:hidden marker:hidden',
				props.class,
			)}
		>
			{props.children}
			<span class="transition duration-300 group-open:rotate-180">
				<IconCaretDownMd />
			</span>
		</summary>
	);
}
